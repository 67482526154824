import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['switch', 'content', 'toggleButton']

  connect() {
    if (this.hasSwitchTarget) {
      this.eventListener = this.switchTarget.addEventListener('click', this.toggleFunction)
    }
  }

  disconnect() {
    if (this.hasSwitchTarget) {
      this.switchTarget.removeEventListener('click', this.eventListener)
    }
  }

  toggleFunction = (e) => {
    e.preventDefault()

    this.toggleButtonTargets.forEach( (icon) => {
      icon.classList.toggle("hidden")
    })

    this.contentTarget.classList.toggle('hidden')
  }
}
