import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["parentSelect", "childSelect", "vintageCaliber"];
  static values = {
    watchModelsUrl: String,
    watchReferenceNumbersUrl: String,
    modelName: { type: String, default: "order" }
  };

  connect() {
    if (this.parentSelectTarget.value === "") {
      this.childSelectTargets.map((child) => child.disabled = true);
    }
  }

  toggleChildSelects({ target }) {
    if (target.value !== "") {
      this.childSelectTargets.map((child) =>
        child.disabled = false
      );
    } else {
      this.childSelectTargets.map((child) => {
        child.disabled = true;
        if (child.slim) {
          child.slim.setSelected(null);
          child.slim.disable();
        } else {
          child.value = null;

        }
      });
    }
  }

  toggleVintageCaliberCheckbox({ target }) {
    if (!this.hasVintageCaliberTarget) {
      return false;
    }

    const brandName = target?.options[target.selectedIndex]?.text;

    if (brandName && brandName.toLowerCase() === "rolex") {
      this.vintageCaliberTarget.classList.remove("hidden");
    } else {
      this.vintageCaliberTarget.classList.add("hidden");
    }
  }

  parentSelectTargetConnected(element) {
    if (this.hasVintageCaliberTarget) {
      this.toggleVintageCaliberCheckbox({ target: element });
    }
  }

  fetchWatchModels({ target }) {
    if (target.value === "") return;

    get(this.watchModelsUrlValue, {
      query: {
        by_watch_brand_id: target.value,
        model_name: this.modelNameValue,
      },
      responseKind: "turbo-stream"
    });
  }

  fetchWatchReferenceNumbers({ target }) {
    if (target.value === "") return;

    get(this.watchReferenceNumbersUrlValue, {
      query: {
        by_watch_brand_id: target.value,
        model_name: this.modelNameValue,
      },
      responseKind: "turbo-stream"
    });
  }
}
