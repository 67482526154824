import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ['radioButton']
  static values = {
    url: String
  }

  getNotificationSettings(e) {
    const newStatus = e.target.value

    get(`${this.urlValue}?status=${newStatus}`, {
      responseKind: "turbo-stream",
    })
  }
}
