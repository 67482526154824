import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { element: String, class: String }

  connect() {
    this.keyboardEventListener = (e) => {
      if(e.keyCode == 27) {
        this.hide()
      }
    }

    this.elementToToggle = document.querySelector(this.elementValue)

    document.querySelector("body").addEventListener("keyup", this.keyboardEventListener)
  }

  show(e) {
    this.elementToToggle.classList.remove(this.classValue)
  }

  hide(e) {
    this.elementToToggle.classList.add(this.classValue)
  }

  disconnect() {
    document.querySelector("body").removeEventListener("keyup", this.keyboardEventListener)
  }
}
