import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "totalPrice", "position"]
  connect() {
    const checkboxes = document.querySelectorAll("input[type=checkbox]")
    this.recalculatePrice()
  }

  togglePosition(e) {
    const checkbox = e.currentTarget.querySelector("input[type='checkbox']")
    if (checkbox == e.target) { return false }

    if (checkbox != undefined && (checkbox.disabled == false || checkbox.disabled == undefined)) {
      checkbox.click()
    }
  }

  recalculatePrice(e) {
    const selectedPositions = this.positionTargets.filter( (position) => {
      if(position.querySelector("input[type=checkbox]:checked") == null) {
        return false 
      }

      return true
    })

    const price = selectedPositions.reduce( (acc, position) => {
      const positionPrice = position.querySelector("[data-price-gross]").dataset.priceGross
      return parseFloat(acc) + parseFloat(positionPrice)
    }, 0.0)

    const localizedPrice = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price)
    this.totalPriceTarget.innerHTML = localizedPrice
  }
}
