import { Controller } from "@hotwired/stimulus";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import XHRUpload from "@uppy/xhr-upload";
import Webcam from "@uppy/webcam";
import * as Turbo from "@hotwired/turbo";
import German from "@uppy/locales/lib/de_DE";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["trigger"];
  static values = { endpoint: String, uploaderPath: String };

  connect() {
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    if (!this.hasTriggerTarget) return false;

    this.uppy = new Uppy({
      allowMultipleUploadBatches: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 15 * 1024 * 1024,
        maxNumberOfFiles: null,
        minNumberOfFiles: null,
        allowedFileTypes: ["image/jpg", "image/jpeg", "image/gif", "image/png"],
      },
      locale: German,
    })
      .use(Dashboard, {
        trigger: this.triggerTarget,
        showProgressDetails: true,
        proudlyDisplayPoweredByUppy: false,
        closeModalOnClickOutside: true,
      })
      .use(XHRUpload, {
        endpoint: this.endpointValue,
        headers: {
          "X-CSRF-Token": csrfToken,
        },
        fieldName: "order_image[image]",
      })
      .use(Webcam, { target: Dashboard, modes: ["picture"], facingMode: "environment", mirror: false });

    this.uppy.on("complete", (result) => {
      if (result.failed.length == 0) {
        if (this.hasUploaderPathValue) {
          get(this.uploaderPathValue, {
            responseKind: "turbo-stream",
          });

          this.uppy.cancelAll()
          this.uppy.getPlugin('Dashboard').closeModal()
        } else {
          Turbo.visit(window.location);
        }
      }
    });
  }

  disconnect() {
    if (!this.uppy) return false;

    this.uppy.close();
  }
}
