import { Controller } from "@hotwired/stimulus";

// mixture of https://github.com/shuding/apple-pencil-safari-api-test/blob/gh-pages/index.js and https://stackoverflow.com/a/17130415

export default class extends Controller {
  static values = {
    placeholder: String,
    searchText: String,
  };

  static targets = ["canvas", "signatureData", "submitButton"];

  connect() {
    this.strokeHistory = [];
    this.lineWidth = 0;
    this.isMouseDown = false;
    this.points = [];
    this.canvasContext = this.canvasTarget.getContext("2d");

    this.canvasTarget.width = this.canvasTarget.width * 2;
    this.canvasTarget.height = this.canvasTarget.height * 2;
    this.canvasContext.strokeStyle = "black";
    this.canvasContext.lineCap = "round";
    this.canvasContext.lineJoin = "round";
  }

  disconnect() {
    this.strokeHistory = [];
  }

  requestIdleCallback(fn) {
    return typeof window.requestIdleCallback == "undefined" ? window.setTimeout(fn, 1) : window.requestIdleCallback(fn);
  }

  /**
   * Remove the previous stroke from history and repaint the entire canvas based on history
   * @return {void}
   */
  undoDraw() {
    this.strokeHistory.pop();
    this.canvasContext.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);

    if (this.strokeHistory.length === 0) {
      this.submitButtonTarget.disabled = true;
    }

    this.strokeHistory.map((stroke) => {
      if (this.strokeHistory.length === 0) return;

      this.canvasContext.beginPath();

      let strokePath = [];
      stroke.map((point) => {
        strokePath.push(point);
        this.drawOnCanvas(strokePath);
      });
    });
  }

  startDrawing(event) {
    const { x, y, pressure } = this.getInputProperties(this.canvasTarget, event);
    this.isMouseDown = true;

    const lineWidth = Math.log(pressure + 1) * 10;
    this.canvasContext.lineWidth = lineWidth; // pressure * 50;

    this.points.push({ x, y, lineWidth });
    this.drawOnCanvas(this.points);
  }

  draw(event) {
    if (!this.isMouseDown) return;
    event.preventDefault();

    const { x, y, pressure } = this.getInputProperties(this.canvasTarget, event);

    // smoothen line width
    this.lineWidth = Math.log(pressure + 1) * 10 * 0.2 + this.lineWidth * 0.8;
    this.points.push({ x, y, lineWidth: this.lineWidth });

    this.drawOnCanvas(this.points);
  }

  stopDrawing(event) {
    const { x, y, pressure } = this.getInputProperties(this.canvasTarget, event);
    this.isMouseDown = false;

    this.requestIdleCallback(() => {
      this.strokeHistory.push([...this.points]);
      this.points = [];

      this.signatureDataTarget.value = this.canvasTarget.toDataURL();
      this.submitButtonTarget.disabled = false;
    });

    this.lineWidth = 0;
  }

  /**
   * This function takes in an array of points and draws them onto the canvas.
   * @param {array} stroke array of points to draw on the canvas
   * @return {void}
   */
  drawOnCanvas(stroke) {
    const l = stroke.length - 1;
    if (stroke.length >= 3) {
      const xc = (stroke[l].x + stroke[l - 1].x) / 2;
      const yc = (stroke[l].y + stroke[l - 1].y) / 2;
      this.canvasContext.lineWidth = stroke[l - 1].lineWidth;
      this.canvasContext.quadraticCurveTo(stroke[l - 1].x, stroke[l - 1].y, xc, yc);
      this.canvasContext.stroke();
      this.canvasContext.beginPath();
      this.canvasContext.moveTo(xc, yc);
    } else {
      const point = stroke[l];
      this.canvasContext.lineWidth = point.lineWidth;
      this.canvasContext.strokeStyle = point.color;
      this.canvasContext.beginPath();
      this.canvasContext.moveTo(point.x, point.y);
      this.canvasContext.stroke();
    }
  }

  getInputProperties(canvas, evt) {
    var rect = canvas.getBoundingClientRect(), // abs. size of element
      scaleX = canvas.width / rect.width,    // relationship bitmap vs. element for X
      scaleY = canvas.height / rect.height;  // relationship bitmap vs. element for Y

    let clientX, clientY, pressure;
    pressure = 0.1;

    if (
      evt.touches &&
      evt.touches[0] &&
      typeof evt.touches[0]["force"] !== "undefined"
    ) {
      if (evt.touches[0]["force"] > 0) {
        pressure = evt.touches[0]["force"];
      }

      clientX = evt.touches[0].clientX;
      clientY = evt.touches[0].clientY;
    } else {
      clientX = evt.clientX;
      clientY = evt.clientY;
      pressure = 1;
    }

    return {
      x: (clientX - rect.left) * scaleX,
      y: (clientY - rect.top) * scaleY,
      pressure: pressure
    };
  }
}