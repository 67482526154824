import { Controller } from "@hotwired/stimulus";
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm'
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm';

export default class extends Controller {
  static targets = ["lightbox"];

  connect() {
    this.lightbox = new PhotoSwipeLightbox({
      gallery: this.element,
      children: 'a:not([data-method])',
      showHideAnimationType: 'none',
      initialZoomLevel: 'fit',
      secondaryZoomLevel: '1',
      maxZoomLevel: '1',
      pswpModule: PhotoSwipe,
      tapAction: 'close'
    });

    this.lightbox.init();
  }

  disconnect() {
    this.lightbox.destroy();
  }
}
