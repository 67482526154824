import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "select", "trigger" ]

  connect() {
    this.triggerTarget.addEventListener("click", this.addParamsToLink.bind(this))
  }

  addParamsToLink(e) {
    const link = e.target.closest("a")
    
    const selectedFragments = this.selectTarget.selectedOptions
    const selectedFragmentIds = []
    for (let index = 0; index < selectedFragments.length; index++) {
      selectedFragmentIds.push(selectedFragments[index].value);
    }

    if (link.href.split("?").length > 1) {
      link.href = link.href + "&selected_fragment_ids=" + selectedFragmentIds
    } else {
      link.href = link.href + "?selected_fragment_ids=" + selectedFragmentIds
    }
  }
}
