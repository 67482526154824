import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = {
    searchPlaceholder: String,
    placeholder: String,
    searchText: String,
    addable: Boolean,
    addableUrl: String,
    addableField: String,
    addableParentField: String,
    addableParentId: Number,
    addableParentParamName: String,
    addableParentFieldCssSelector: String,
  };

  connect() {
    const displayValues = {};
    displayValues.searchPlaceholder = (this.hasSearchPlaceholderValue ? this.searchPlaceholderValue : "Suchen");
    displayValues.placeholder = (this.hasPlaceholderValue ? this.placeholderValue : "Eintrag auswählen");
    displayValues.searchText = (this.hasSearchTextValue ? this.searchTextValue : "Keine Einträge gefunden");

    const addable = this.addableCallback();

    this.select = new SlimSelect({
      select: this.element,
      settings: {
        allowDeselect: true,
      },
      events: {
        addable: addable
      },
      ...displayValues
    });
  }

  addableCallback() {
    if (this.hasAddableValue && this.hasAddableUrlValue && this.hasAddableFieldValue) {
      return async (value) => {
        let parentValue;

        if (this.hasAddableParentFieldValue && this.hasAddableParentFieldCssSelectorValue) {
          parentValue = document.querySelector(this.addableParentFieldCssSelectorValue)?.value;
        }

        const request = await post(this.addableUrlValue, {
          body: {
            [this.addableParentParamNameValue]: {
              [this.addableFieldValue]: value,
              [this.addableParentFieldValue]: this.addableParentIdValue,
              ...(parentValue && { [this.addableParentFieldValue]: parentValue })
            }
          },
          responseKind: "json",
          contentType: "application/json",
        });

        if (request.ok) {
          const watch_brand = await request.json;

          return {
            value: watch_brand.id,
            text: watch_brand[this.addableFieldValue],
          };
        }
      };
    } else if (this.hasAddableValue && !this.hasAddableUrlValue && !this.hasAddableFieldValue) {
      return (value) => {
        return value;
      };
    }

    return false;
  }

  disconnect() {
    this.select.destroy();
  }
}
