import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = {
    searchPlaceholder: String,
    placeholder: String,
    searchText: String,
  };

  async fetch(e) {
    const customerId = e.target.value;

    const response = await get(`/customers/${customerId}/details`, {
      responseKind: "turbo-stream",
    });
  }

  disconnect() {
    this.select && this.select.destroy();
  }
}
